import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import GroupsIcon from "@mui/icons-material/Groups";
import CommunityImage2 from "../../assets/smile.png";

import KidingSocial1 from "../../assets/Kiding/Social1.jpg";
import PalunganSocial1 from "../../assets/PaLungan/Social1.jpg";
import PalunganSocial2 from "../../assets/PaLungan/Social2.jpg";

import KerebanganSocial1 from "../../assets/Kerebangan/Social1.jpg";
import KerebanganSocial2 from "../../assets/Kerebangan/Social2.jpg";

import RusuSocial1 from "../../assets/Rusu/Social1.jpg";
import RusuSocial2 from "../../assets/Rusu/Social2.jpg";

import TelinganSocial1 from "../../assets/Telingan/Social1.jpg";
import TelinganSocial2 from "../../assets/Telingan/Social2.jpg";

import UkatSocial1 from "../../assets/Ukat/Social1.jpg";
import UkatSocial2 from "../../assets/Ukat/Social2.jpg";

import { format } from "date-fns";

const KidingSocialImages = [KidingSocial1];
const PalunganSocialImages = [PalunganSocial1, PalunganSocial2];
const KerebanganSocialImages = [KerebanganSocial1, KerebanganSocial2];
const RusuSocialImages = [RusuSocial1, RusuSocial2];
const TelinganSocialImages = [TelinganSocial1, TelinganSocial2];
const UkatSocialImages = [UkatSocial1, UkatSocial2];

const SocialImpact = (props) => {
  const { origin, distillationData, payment } = props;

  const [beneficiaryCount, setBeneficiaryCount] = useState(0);
  const [lastDistillation, setLastDistillation] = useState(null);

  const [socialImage, setSocialImage] = useState(CommunityImage2);

  useEffect(() => {
    if (distillationData && distillationData.length > 0) {
      const beneficiarySet = new Set();
      for (const distillation of distillationData) {
        const distillationInfo = JSON.parse(distillation["PrivateInfo"]);
        beneficiarySet.add(distillationInfo["Distilled By"]);
      }
      setBeneficiaryCount(beneficiarySet.size);

      setLastDistillation({
        date: format(new Date(distillationData[0]["ProcessDate"]), "MMM dd, yyyy"),
      });
    }
  }, [distillationData]);

  useEffect(() => {
    if (origin && origin.name) {
      if (origin.name.toLowerCase().includes("lungan")) {
        const random = Math.floor(Math.random() * PalunganSocialImages.length);
        setSocialImage(PalunganSocialImages[random]);
      } else if (origin.name.toLowerCase().includes("kiding")) {
        const random = Math.floor(Math.random() * KidingSocialImages.length);
        setSocialImage(KidingSocialImages[random]);
      } else if (origin.name.toLowerCase().includes("telingan")) {
        const random = Math.floor(Math.random() * TelinganSocialImages.length);
        setSocialImage(TelinganSocialImages[random]);
      } else if (origin.name.toLowerCase().includes("kerebangan")) {
        const random = Math.floor(Math.random() * KerebanganSocialImages.length);
        setSocialImage(KerebanganSocialImages[random]);
      } else if (origin.name.toLowerCase().includes("rusu")) {
        const random = Math.floor(Math.random() * RusuSocialImages.length);
        setSocialImage(RusuSocialImages[random]);
      } else if (origin.name.toLowerCase().includes("ukat")) {
        const random = Math.floor(Math.random() * UkatSocialImages.length);
        setSocialImage(UkatSocialImages[random]);
      }
    }
  }, [origin]);

  return (
    <div class="reveal" id="socialImpact">
      <Card style={{ backgroundColor: "#FAF2DA" }}>
        <CardContent>
          <Typography gutterBottom variant="overline" color="#395144" fontSize={20} fontWeight="bold">
            Social Impact
          </Typography>
          <div class="reveal">
            <Box mb={2} sx={{ borderRadius: "16px", overflow: "hidden", boxShadow: "0px 5px 10px rgba(0, 0, 0, 0.3)" }}>
              <CardMedia component="img" src={socialImage} alt="Image description" sx={{ height: 200, objectFit: "cover" }} />
            </Box>
          </div>

          {beneficiaryCount > 0 && (
            <div class="reveal">
              <Stack direction={"row"} spacing={2} alignItems={"center"} pb={1}>
                <Box sx={{ borderRadius: "50%", border: 1, borderColor: "#395144", px: 0.7, py: 0.3 }}>
                  <GroupsIcon sx={{ color: "#395144" }} />
                </Box>
                <Typography color="#395144" variant="body2">
                  A total of{" "}
                  {beneficiaryCount > 1 ? (
                    <span>
                      <strong>{beneficiaryCount} families</strong> were
                    </span>
                  ) : beneficiaryCount === 1 ? (
                    <span>
                      <strong>{beneficiaryCount} family</strong> was
                    </span>
                  ) : null}{" "}
                  involved in distilling the raw material used for this product
                  {lastDistillation ? (
                    <span>
                      {" "}
                      and the raw material was <strong>distilled</strong> on <strong>{lastDistillation.date}</strong>, at{" "}
                      <strong>{origin.name}</strong>.
                    </span>
                  ) : (
                    <span>.</span>
                  )}
                </Typography>
              </Stack>
            </div>
          )}

          {payment.payer && payment.recipient ? (
            <div class="reveal">
              <Typography gutterBottom color="#395144" variant="body2">
                On <strong>{format(new Date(payment.date), "MMM dd, yyyy")}</strong>, <strong>{payment.payer}</strong> paid{" "}
                <strong>{payment.recipient}</strong> for the raw ingredient harvested from the community.
              </Typography>
            </div>
          ) : null}

          <div class="reveal">
            <Typography gutterBottom color="#395144" variant="body2">
              Thank you for supporting the livelihood of the community.
            </Typography>
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default SocialImpact;
